import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Fqs from "../../Component/FQS/fqs";
import Productcard from "../../Component/productcard/productcard";
import { getUser } from "../../Common/css/api-instans/api-instan";
import { useDispatch, useSelector} from 'react-redux'
import { setToken, setUser } from "../../redux/action";
const Home = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [subHome, setSubHome] = useState([]);
  const [about, setAbout] = useState([]);
  const [product, setProduct] = useState([]);
  const [producthader, setProducthader] = useState(null);
  const [instructionheader, setInstructionheader] = useState(null);
  const [testimonialsSubHeaderData, setTestimonialsSubHeaderData] = useState(
    []
  );
  const [testimonialsHeaderData, setTestimonialsHeaderData] = useState(null);
  const [howitwork, setHowitwork] = useState([]);
  const [link, setLink] = useState([]);
  const [sectiondata, setSectiondata] = useState(null);
  const [link1, setLink1] = useState([]);
  const token = useSelector(state=>state?.token)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getUser("user/admin_singin", {
          userNameOrMobileNo: "guestuser@gmail.com",
          password: "Guest@123",
        });
        dispatch(setToken(userData?.jwtResponse?.accessToken))
        dispatch(setUser(userData?.jwtResponse?.data))
   
      } catch (error) {
       
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []); 

  const fatchData = async () => {
    try {
      const userData = await getUser("websitecontentsql/get_websitecontent", {
        sectiontype: "",
      },token);

      userData?.data?.map((item) => {
        switch (item.sectiontype) {
          case "Home Header":
            setUserData(item);
            break;
          case "Home Sub Header":
            setSubHome((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          case "About Us":
            setAbout(item);
            break;
          case "Product Header":
            setProducthader(item);
            break;
          case "Product Sub Header":
            setProduct((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          case "Instruction Header":
            setInstructionheader(item);
            break;
          case "secation-3":
            setSectiondata(item);
            break;
          case "Instruction Sub Header ":
            setHowitwork((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });

          case "Testimonials Sub Header":
            if(item?.sectiontype == "Testimonials Sub Header" ){
              setTestimonialsSubHeaderData((prevData) => {
                if (
                  !prevData.find(
                    (dataItem) =>
                      dataItem.websitecontentid === item.websitecontentid
                  )
                ) {
                  return [...prevData, item];
                } else {
                  return prevData;
                }
              });
            }
            break;
          case "Testimonials Header":
            setTestimonialsHeaderData(item);
            break;
          case "link":
            setLink((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          case "link-1":
            setLink1((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          default:
            break;
        }
      });
    } catch (error) {
      setError(error);
    }
  };
  const [slider, setSlider] = useState([]);
  const sliderFatchData = async () => {
    try {
      const userData = await getUser("websitecontentsql/get_dynamicimage", {
        sectiontype: "slider",
      },token);
      setSlider(userData?.data);
    } catch (error) {
      setError(error);
    }
  };
  const [review, setRwview] = useState();
  const reviewImage = async () => {
    try {
      const userData = await getUser("websitecontentsql/get_dynamicimage", {
        sectiontype: "testimonials",
      },token);
      setRwview(userData?.data[0]);
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    if (token) {
      fatchData();
      sliderFatchData();
      reviewImage();
    }
  }, [token]);

  const [indexSlide, setIndexslide] = useState(0);
  var settings = {
    dots: product.length > 3 ? true : false,
    infinite: product.length > 3 ? true : false,
    centerPadding: "60px",
    className: "center",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
        },
      },
    ],
  };

  return (
    <>
      <div className="container">
        <div className="banner-sec">
          <div className="row g-3">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration={2000}
            >
              <div className="banner-details">
                <div className="banner-info">
                  <div
                    dangerouslySetInnerHTML={{ __html: userData?.title }}
                  ></div>
                  <p className="mt-4 mb-4">{userData?.description}</p>
                  <div className="banner-app-btn mt-2">
                    {link.length > 0 &&
                      link.map((item, index) => (
                        <>
                          <a key={item?.websitecontentid} href={item?.link} className="">
                            <img
                              src={item.imageurl}
                              className="img-fluid me-3"
                            />
                          </a>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration={2000}
            >
              <div className="banner-img text-center">
                <img src={userData?.imageurl} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="order-sec">
        <div className="container">
          <div className="row g-3">
            {subHome.length > 0 &&
              subHome?.map((item, index) => (
                <div
                  className="col-lg-4"
                  data-aos="flip-up"
                  data-aos-duration={2000}
                  key={index}
                >
                  <div className="oder-detail-box text-center">
                    <div className="order-icon-box mb-3">
                      <img src={item?.imageurl} className="img-fluid" />
                    </div>
                    <h4>{item?.title}</h4>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="about-sec">
        <div className="container border-bottom">
          <div className="row g-3">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration={2000}
            >
              <div className="about-img">
                <img src={about?.imageurl} className="img-fluid" />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration={2000}
            >
              <div className="about-details">
                <div className="about-info">
                  {/* <h6>About Us</h6> */}
                  <h2>{about?.title}</h2>

                  <div
                    dangerouslySetInnerHTML={{ __html: about?.description }}
                  ></div>

                  <p />
                  {about?.title && (
                    <a href={about?.link} type="button" className="btn btn-orange mt-2">
                      Download Now{" "}
                      <img
                        src="./assets/imgs/arrow.png"
                        className="img-fluid"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-sec">
        <div className="container">
          <div
            className="product-title text-center"
            data-aos="zoom-in"
            data-aos-duration={2000}
          >
            <h5>{producthader?.title}</h5>
            <h2>{producthader?.description}</h2>
          </div>

          <div className="product-slider row">
          
            <Slider {...settings}>
              {product.length > 0 &&
                product.map((item, index) => (
                  <div
                    className="col-lg-4"
                    data-aos="fade-up"
                    data-aos-duration={2000}
                    key={index}
                  >
                    <Productcard productData={item} />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="how-it-sec">
        <div className="container">
          <div
            className="how-it-title text-center mb-5"
            data-aos="zoom-in"
            data-aos-duration={2000}
          >
            <h5 className="mb-3">{instructionheader?.name}</h5>
            <h2 className="mb-3">{instructionheader?.title}</h2>
            <p className="mb-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: instructionheader?.description,
                }}
              ></div>
            </p>
          </div>
          <div className="row g-3">
            {howitwork.length > 0 &&
              howitwork?.map((item, index) => (
                <div
                  className="col-lg-4"
                  data-aos="fade-down"
                  data-aos-duration={2000}
                  key={index}
                >
                  <div className="how-to-work-box text-center">
                    <div className="number-box mb-5">{index + 1}</div>
                    <h4>{item?.title}</h4>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="review-sec">
        <img src={review?.imageurl} className="img-fluid review-food-img" />
        <div className="container">
          <div
            className="review-title text-center mb-5"
            data-aos="zoom-in"
            data-aos-duration={2000}
          >
            <h5>{testimonialsHeaderData?.title}</h5>
            <h2>{testimonialsHeaderData?.description}</h2>
          </div>
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="review-slider">
                <div id="carouselExample" className="carousel slide">
                  <div className="carousel-inner">
                    {testimonialsSubHeaderData.map((item, index) => (
                      <div
                        className={`carousel-item ${
                          index == 0 ? "active" : ""
                        }`}
                        key={index}
                      >
                        <div className="review-box text-center">
                          <div className="client-box d-flex justify-content-center mb-3">
                            <div className="client-img me-3">
                              <img src={item?.imageurl} className="img-fluid" />
                            </div>
                            <div className="client-details text-start">
                              <h5>{item?.name}</h5>
                              <p>{item?.title}</p>
                            </div>
                          </div>
                          <p>{item?.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <i className="fa fa-long-arrow-left" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="carouselExampleIndicators" className="carousel slide">
                <div className="carousel-indicators">
                  {slider.length > 0 &&
                    slider.map((item, index) => (
                      <button
                      key={index}
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={index == indexSlide ? "active" : ""}
                        aria-current="true"
                        aria-label={`Slide ${index}`}
                        onClick={() => setIndexslide(index)}
                      />
                    ))}
                </div>

                <div className="carousel-inner">
                  {slider.length > 0 &&
                    slider.map((item, index) => (
                      <div
                        className={`carousel-item ${
                          index == indexSlide ? "active" : ""
                        }`}
                        key={index}
                      >
                        <img
                          src={item?.imageurl}
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <Fqs />
        <div
          className="simple-way-main"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <div className="container">
            <div className="simple-way-sec">
              <div className="row g-3">
                <div className="col-lg-6">
                  <div className="simple-way-details">
                    <div className="simple-way-info">
                      <div
                        dangerouslySetInnerHTML={{ __html: sectiondata?.title }}
                      ></div>

                      <div className="simple-way-app-btn mt-5">
                        {link1.length > 0 &&
                          link1.map((item, index) => (
                            <>
                              <a key={index} href={item?.link} className="">
                                <img
                                  src={item.imageurl}
                                  className="img-fluid me-3"
                                />
                              </a>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simple-way-main-img">
                <img src={sectiondata?.imageurl} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Home;
