import React, { useEffect, useState } from "react";
import { getUser } from "../../Common/css/api-instans/api-instan";
import {  useSelector} from 'react-redux'
export const Termsandconditions = () => {
  const [error, setError] = useState(null);
  const [terms, setTerms] = useState(null);
  const [link, setLink] = useState([])
  const token = useSelector(state=>state?.token)
  const fatchData = async () => {
    try {
      const userData = await getUser("websitecontentsql/get_staticpages", {
        pagecode: "Terms and Privacy",
      },token);
      setTerms(userData?.data[0]);
    } catch (error) {
      setError(error);
    }
  };

  const [termshader, setTermshader] = useState(null);
  const getDatatHader = async () => {
    try {
      const userData = await getUser("websitecontentsql/get_websitecontent", {
        sectiontype: "",
      },token);
      userData?.data?.map((item) => {
        switch (item.sectiontype) {
          case "Conditions page":
            setTermshader(item);
            break;
            case "link":
              setLink((prevData) => {
                if (
                  !prevData.find(
                    (dataItem) =>
                      dataItem.websitecontentid === item.websitecontentid
                  )
                ) {
                  return [...prevData, item];
                } else {
                  return prevData;
                }
              });
              break;
          default:
            break;
        }
      });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fatchData();
    getDatatHader();
  }, []);
  return (
    <>
      <div className="container">
        <div className="banner-sec">
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="banner-details">
                <div className="banner-info">
                  <div
                    dangerouslySetInnerHTML={{ __html: termshader?.title }}
                  ></div>
                  <p className="mt-4 mb-4">{termshader?.description}</p>
                  <div className="banner-app-btn mt-2">
                  {link.length > 0  && link.map((item, index)=>(<>
                      <a key={index} href={item?.link} className="">
                    <img
                      src={item.imageurl}
                      className="img-fluid me-3"
                    />
                 </a>
                    </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-img text-center">
                <img src={termshader?.imageurl} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: terms?.content }}></div>
      </div>
    </>
  );
};
