import { SET_TOKEN, SET_USER } from "./action-type";

// reducers.js
const initialState = {
    user: null,
    token: null
  };
  
  const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return Object.assign({}, state, { user: action.user });
          case SET_TOKEN:
            return Object.assign({}, state, { token: action.token });
      
      default:
        return state;
    }
  };
  
  export default rootReducer;
  