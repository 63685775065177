
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getUser } from '../../Common/css/api-instans/api-instan'
import {  useSelector} from 'react-redux'
const Header = () => {
  const [logo, setLogo] =  useState({})
  const [error, setError] =  useState({})
  const token = useSelector(state=>state?.token)
  const sliderFatchData= async () =>{
    try {
      const userData = await getUser("websitecontentsql/get_dynamicimage", {
        sectiontype:"Logo"
      },token);
      setLogo(userData?.data[0])
     
    } catch (error) {
      setError(error);
    }
  }

  useEffect(()=>{
    sliderFatchData()
  },[])
  return (
    <>
    <div className="container">
    <nav className="navbar navbar-expand-lg">
    <div className="container-fluid p-0">
      <a className="navbar-brand" href="#">
        <img src={logo?.imageurl} className="img-fluid" width={100}/>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className="nav-link active me-3" aria-current="page" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link me-3" to="/about-us">
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link me-3" to="/contact-us">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  </div>
  </>
  )
}

export default Header