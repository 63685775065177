
import axios from 'axios';
import AppStore from '../../../redux/store'

const BASE_URL =process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

const getUser = async (url, param, token) => {
  try {
    const response = await api.post(url, param,
    { headers: {
    Authorization: `Bearer ${token}` // Assuming your token is a JWT
  }});
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add more API functions as needed

export { getUser };
