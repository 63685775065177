import React, { useEffect, useState } from 'react'
import { getUser } from '../../Common/css/api-instans/api-instan';
import {  useSelector} from 'react-redux'
const Fqs = () => {
    const [fqs, setFqs] =  useState([])
    const [fqsIndex, setFqsIndex] =  useState()
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const token = useSelector(state=>state?.token)
    useEffect(()=>{
      fqsData()
      fatchData()
    },[])
    const fatchData = async () => {
      try {
        const userData = await getUser("websitecontentsql/get_websitecontent", {
          sectiontype: "faqs",
        },token);
      setData(userData.data[0])
      } catch (error) {
        setError(error);
      }
    };

    const fqsData = async () => {
      try {
        const userData = await getUser('websitecontentsql/get_faq',{
          question:""
        }, token);
        setFqs(userData.data);
      
      } catch (error) {
        setError(error);
        
      }
    };
    const handelClick = (index) =>{
        if(index === fqsIndex){
          setFqsIndex()
        }else{
          setFqsIndex(index)
        }
    }
  return (
    <div className="faq-sec">
    <div className="faq-title text-center mb-5" data-aos="zoom-in" data-aos-duration={2000}>
      <h2>
        {data?.title} 
        <img src={data?.imageurl} className="img-fluid" /> <br />
        <span>{data?.description}</span>
      </h2>
    </div>
    <div className="container">
      <div className="accordion"  
      data-aos="fade-up"
            data-aos-duration={2000}
            >
        {fqs.length > 0 && fqs.map((item, index)=>(
          <div
            className={`accordion-item border-0 border-bottom ${fqsIndex === index ? "active" : ""}`}
           key={index}
          >
            <div className="accordion-title d-flex justify-content-between">
              <span>{item.question}</span>
              <button type='button' className='btn' onClick={()=>handelClick(index)}>
                <img src={fqsIndex === index ? "assets/imgs/minus.png" : "assets/imgs/plus.png"} alt='image' />
              </button>
            </div>
            <div className="accordion-content">
              <p>
                {item?.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  
  )
}

export default Fqs
