import 'bootstrap/dist/css/bootstrap.min.css';
import'./Common/css/common.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Component/header/header';
import Home from './Pages/home/home';
import { useEffect } from 'react';
import Aos from 'aos';
import Footer from './Component/footer/footer';
import Aboutus from './Pages/about/aboutus';
import Contactus from './Pages/contact/contactus';
import { Termsandconditions } from './Pages/termsandconditions/Termsandconditions';
import Privacyplociy from './Pages/privacypolice/privacyplociy';
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  useEffect(()=>{
    AOS.init();
    AOS.refresh();
  },[])
  return (
   <BrowserRouter>
   <Header/>
   <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/about-us' element={<Aboutus />} />
    <Route path='/contact-us' element={<Contactus />} />
    <Route path='/termsandconditions' element={<Termsandconditions />} />
    <Route path='/privacyplociy' element={<Privacyplociy />} />
   </Routes>
   <Footer />
   </BrowserRouter>
  );
}

export default App;
