import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../../Common/css/api-instans/api-instan";
import { useSelector } from "react-redux";
import { Phone, Mail } from "@mui/icons-material";

const Footer = () => {
  const [logo, setLogo] = useState({});
  const [error, setError] = useState({});
  const [socialicon, setSocialicon] = useState([]);
  const [footer, setFooter] = useState(null);
  const [link, setLink] = useState([]);
  const token = useSelector((state) => state?.token);
  const sliderFatchData = async () => {
    try {
      const userData = await getUser(
        "websitecontentsql/get_dynamicimage",
        {
          sectiontype: "Logo",
        },
        token
      );
      setLogo(userData?.data[0]);
    } catch (error) {
      setError(error);
    }
  };

  const fatchData = async () => {
    try {
      const userData = await getUser(
        "websitecontentsql/get_websitecontent",
        {
          sectiontype: "",
        },
        token
      );

      userData?.data?.map((item) => {
        switch (item.sectiontype) {
          case "socialicon footer":
            setSocialicon((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          case "link":
            setLink((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          case "Footer":
            setFooter(item);
            break;

          default:
            break;
        }
      });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    sliderFatchData();
    fatchData();
  }, []);
  return (
    <div className="footer-main">
      <div className="container">
        <div className="row g-4 pb-3 border-bottom">
          <div className="col-md-4 col-sm-6 ">
            <div className="footer-box">
              <Link href="/">
                <img
                  src={logo?.imageurl}
                  className="img-fluid mb-3"
                  width={100}
                />
              </Link>
              <div className="sociel-icon">
                {socialicon?.length > 0 &&
                  socialicon.map((item, index) => (
                    <a
                      href={item?.link}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item?.socialicon }}
                    ></a>
                  ))}
                {/* <a href="#">
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
            <a href="#">
              <i className="fa fa-twitter" aria-hidden="true" />
            </a>
            <a href="#">
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a> */}
              </div>
              {footer?.description && (
                <div className="footer-links mt-4">
                  <Link
                    to="/termsandconditions"
                    className="me-3 d-inline-block"
                  >
                    Terms &amp; Conditions
                  </Link>
                  <Link to="/privacyplociy" className="me-3 d-inline-block">
                    Privacy
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4 col-6 d-flex justify-content-sm-center">
            <div className="footer-app-btn">
              <h4 className="mb-3 fw-bold">Contact-Us</h4>
              <div className="mb-3 d-flex align-items-center">
                <Phone sx={{ mr: 1 }} /> <span>8200244766</span>
              </div>
              <div className="mb-3 d-flex align-items-center">
                <Mail sx={{ mr: 1 }} /> <span>sales@ihochinfotech.com</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 d-flex justify-content-sm-center justify-content-md-end">
            <div className="footer-app-btn">
              <h4 className="mb-3 fw-bold">{footer?.title}</h4>
              {link?.length > 0 &&
                link.map((item, index) => (
                  <a key={index} href={item?.link} className="d-block mb-3">
                    <img src={item?.imageurl} className="img-fluid" />
                  </a>
                ))}
            </div>
          </div>
        </div>
        <div className="copy-right-box pt-3 text-center">
          {footer?.description}
        </div>
      </div>
    </div>
  );
};

export default Footer;
