import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import appImage from "../../Common/utils/app.png";
import appstore from "../../Common/utils/appstore.png"
import playstore from "../../Common/utils/playstore.png"

const Productcard = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="product-box mt-5">
        <div className="product-box-img mb-5">
          <img
            src={props?.productData?.imageurl}
            className="img-fluid"
            alt="Product"
          />
        </div>
        <h4 className="mb-3">{props?.productData?.title}</h4>
        <button
          type="button"
          className="btn btn-outline-warning"
          onClick={toggleModal}
        >
          Order Now
        </button>
      </div>

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="order-product-modal-title"
        aria-describedby="order-product-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: 1,
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fefefe",
            borderRadius: "10px",
            padding: "20px",
            border: "1px solid #888",
            width: "80%",
            maxWidth: "600px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={toggleModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="order-product-modal-title"
            variant="h6"
            component="h2"
          >
            Order Your Favorite Today!
          </Typography>
          <Typography id="order-product-modal-description" sx={{ mt: 2 }}>
            Download our app to order your favorite items now and enjoy top-quality products delivered right to your door!
          </Typography>
          <img
            src={appImage}
            alt="App"
            style={{ maxWidth: "100%", height: "auto", marginTop: "20px" }}
          />
          <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
            <img src={appstore} alt="App Store" style={{ maxWidth: "45%", height: "auto" }} />
            <img src={playstore} alt="Play Store" style={{ maxWidth: "45%", height: "auto" }} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Productcard;
