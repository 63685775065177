import React, { useEffect, useState } from "react";
import { getUser } from "../../Common/css/api-instans/api-instan";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from "react-bootstrap";
import {  useSelector} from 'react-redux'
const Contactus = () => {
  const [error, setError] = useState(null);
  const [contact, setContact] = useState(null);
  const [link, setLink] = useState([])
  const [submitLoding, setSubmitLoding] = useState(false)
  const token = useSelector(state=>state?.token)
  const fatchData = async () => {
    try {
      const userData = await getUser("websitecontentsql/get_websitecontent", {
        sectiontype: "Contact page",
      },token);
      userData?.data?.map((item) => {
        switch (item.sectiontype) {
          case "Contact page":
            setContact(item);
            break;
            case "link":
              setLink((prevData) => {
                if (
                  !prevData.find(
                    (dataItem) =>
                      dataItem.websitecontentid === item.websitecontentid
                  )
                ) {
                  return [...prevData, item];
                } else {
                  return prevData;
                }
              });
              break;
          default:
            break;
        }
      });
      setContact(userData?.data[0]);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fatchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      clientname: '',
      clientemailid: '',
      clientphone: '',
      message: ''
    },
    validationSchema: Yup.object({
      clientname: Yup.string().required("Name is required"),
      clientemailid: Yup.string().email("Invalid email address").required("Email is required"),
      clientphone: Yup.string().matches(/^\d{10}$/, "Invalid phone number").required("Phone number is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async(values) => {
      setSubmitLoding(true)
      try {
        const userData = await getUser("websitecontentsql/save_websitecontactus",values, token)
        if(userData.status ==  "200"){
          setSubmitLoding(false)
          formik?.resetForm();
        }else{
          setSubmitLoding(false)
          formik?.resetForm();
        }
      } catch (error) {
        setSubmitLoding(false);
        formik?.resetForm();
        setError(error);
      }
    },
  });

  return (
    <>
      <div className="container">
        <div className="banner-sec">
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="banner-details">
                <div className="banner-info">
                  <div
                    dangerouslySetInnerHTML={{ __html: contact?.title }}
                  ></div>
                  <p className="mt-4 mb-4">{contact?.description}</p>
                  <div className="banner-app-btn mt-2">
                  {link.length > 0  && link.map((item, index)=>(<>
                      <a key={index} href={item?.link} className="">
                    <img
                      src={item.imageurl}
                      className="img-fluid me-3"
                    />
                 </a>
                    </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-img text-center">
                <img src={contact?.imageurl} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-box">
     {contact?.title && <div className="container">
        <div className="row">
          <h4 style={{ textAlign: "center" }}>We'd love to hear from you!</h4>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row input-container">
            <div className="col-xs-12">
              <div className="styled-input wide">
                <input
                  type="text"
                  id="clientname"
                  name="clientname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.clientname}
                  required
                />
                <label htmlFor="clientname">Name</label>
                {formik.touched.clientname && formik.errors.clientname ? <div className="error">{formik.errors.clientname}<span className="caret"></span></div> : null}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="styled-input">
                <input
                  type="text"
                  id="clientemailid"
                  name="clientemailid"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.clientemailid}
                  required
                />
                <label htmlFor="clientemailid">Email</label>
                {formik.touched.clientemailid && formik.errors.clientemailid ? <div className="error">{formik.errors.clientemailid}<span className="caret"></span></div> : null}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="styled-input" style={{ float: "right" }}>
                <input
                  type="text"
                  id="clientphone"
                  name="clientphone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.clientphone}
                  required
                />
                <label htmlFor="clientphone">Phone Number</label>
                {formik.touched.clientphone && formik.errors.clientphone ? <div className="error">{formik.errors.clientphone}<span className="caret"></span></div> : null}
              </div>
            </div>
            <div className="col-xs-12">
              <div className="styled-input wide">
                <textarea
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  required
                />
                <label htmlFor="message">Message</label>
                {formik.touched.message && formik.errors.message ? <div className="error">{formik.errors.message}<span className="caret"></span></div> : null}
              </div>
            </div>
            <div className="col-xs-12">
              <button type="submit" className="btn-lrg submit-btn">{submitLoding ?(
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Send Message"
                  )}</button>
            </div>
          </div>
        </form>
      </div>}
    </div>
    </>
  );
};

export default Contactus;
