import React, { useEffect, useState } from "react";
import { getUser } from "../../Common/css/api-instans/api-instan";
import {  useSelector} from 'react-redux'
const Aboutus = () => {
  const [aboutpage, setAboutPage] = useState(null);
  const [mission, setMission] = useState(null);
  const [vision, setVision] = useState(null);
  const [error, setError] = useState(null);
  const [link, setLink] = useState([]);
  const token = useSelector(state=>state?.token)
  const fatchData = async () => {
    try {
      const userData = await getUser("websitecontentsql/get_websitecontent", {
        sectiontype: "",
      },token);

      userData?.data?.map((item) => {
        switch (item.sectiontype) {
          case "About Page":
            setAboutPage(item);
            break;
          case "Our Mission":
            setMission(item);
            break;
          case "Our Vision":
            setVision(item);
            break;
          case "link":
            setLink((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              } else {
                return prevData;
              }
            });
            break;
          default:
            break;
        }
      });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fatchData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="banner-sec">
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="banner-details">
                <div className="banner-info">
                  <div
                    dangerouslySetInnerHTML={{ __html: aboutpage?.title }}
                  ></div>
                  <p className="mt-4 mb-4">{aboutpage?.description}</p>
                  <div className="banner-app-btn mt-2">
                    {link.length > 0 &&
                      link.map((item, index) => (
                        <>
                          <a key={index} href={item?.link} className="">
                            <img
                              src={item.imageurl}
                              className="img-fluid me-3"
                            />
                          </a>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-img text-center">
                <img src={aboutpage?.imageurl} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-box">
        <div className="container">
          <div className="row g-3">
            <div className="col-md-6">
              <div className="left-right-sec-info">
                <div className="left-right-sec-details">
                  <h5 className="card-title">{mission?.title}</h5>
                  <p>{mission?.description}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src={mission?.imageurl} className="img-fluid" />
            </div>
            <div className="col-md-6">
              <img src={vision?.imageurl} className="img-fluid" />
            </div>
            <div className="col-md-6">
              <div className="left-right-sec-info">
                <div className="left-right-sec-details">
                  <h5 className="card-title">{vision?.title}</h5>
                  <p>{vision?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;